export const COUNTRIES = 'Country/ReadCountryList';
export const CREATE_COMPANY_EP = 'CentralAddressBook/CreateCabOrganization';
export const CREATE_CAB_EP = 'CentralAddressBook/CreateCabEntry';
export const CAB_ENTRIES_EP = 'CentralAddressBook/ReadCabPersonList';
export const CAB_PERSON_EP = `CentralAddressBook/ReadCabPersonList/${name}`;
export const CAB_COMPANY_EP = `CentralAddressBook/ReadCabOrganizationList/${name}`;
export const CAB_IMAGE_UPLOAD_EP = 'CentralAddressBook/UploadPersonImage';
export const CAB_FILTER_EP = 'CentralAddressBook/PersonFilter';
export const CAB_DELETE_EP = 'CentralAddressBook/DeleteCabEntry';
export const RISK_DROPDWON_EP = 'Risk/ReadRiskDropdowns';
export const CREATE_RISK_EP = 'Risk/CreateRisk';
export const SAVE_PBS_RISK_EP = 'PbsRisk/Create';
export const SAVE_PBS_INSTRUCTION_EP = 'PbsInstruction/CreatePbsInstruction';
export const READ_RISK_LIST_EP = 'Risk/ReadRiskList';
export const RISK_FILTER_LIST_EP = 'PbsRisk/ReadRiskByPbsProduct/';
export const DELETE_RISK_EP = 'PbsRisk/DeletePbsRisk';
export const QUALITY_FILTER_EP = 'PbsQuality/ReadQualityByPbsProduct/';
export const CREATE_QUALITY_EP = 'Quality/CreateQuality'; //link to Quality api
export const SAVE_PBS_QUALITY_EP = 'PbsQuality/Create';
export const DELETE_QUALITY_EP = 'PbsQuality/DeletePbsQuality';
export const READ_MATERIALS_EP = 'PbsResource/ReadMaterial';
export const CREATE_MATERIALS_EP = 'PbsResource/CreatePbsMaterial';
export const CREATE_TOOLS_EP = 'PbsResource/CreatePbsTool';
export const DELETE_MATERIALS_EP = 'PbsResource/DeletePbsMaterial';
export const DELETE_TOOLS_EP = 'PbsResource/DeletePbsTool';
export const CREATE_LABOUR_EP = 'PbsResource/CreatePbsLabour';
export const DELETE_LABOUR_EP = 'PbsResource/DeletePbsLabour';
export const CREATE_CONSUMABLE_EP = 'PbsResource/CreatePbsConsumable';
export const DELETE_CONSUMABLE_EP = 'PbsResource/DeletePbsConsumable';
export const CAB_UNIQ_CONTACT_EP =
  'CentralAddressBook/IsUsedUniqueContactDetailsFilter';
export const IS_CAB_EXIST_EP = 'CentralAddressBook/IsExistCabEntry';
export const IS_COUNTRY_EP = 'CentralAddressBook/GetValidCountries';
export const CAB_EXPORT_EP = 'CentralAddressBook/ReadCabPersonList';
export const NO_CAB_COMPANY_EP = 'CentralAddressBook/GetUnassignedCompanyList';
export const READ_INSTRUCTION_DROPDOWN_EP =
  'PbsInstruction/ReadInstructionDropdowns';
export const READ_INSTRUCTION_LIST_EP = 'PbsInstruction/ReadInstructionsList';
export const UPLOAD_INSTRUCTION_DOC_EP = 'PbsInstruction/UploadDocument';
export const CREATE_INSTRUCTION_EP = 'PbsInstruction/CreateInstructions';
export const COMPETENCE_FILTER_EP = 'Competence/ReadCompetenceByPbsId/';
export const MILESTONE_FILTER_EP = 'VP/GetMilestoneListForPbs/';
export const READ_COMPETENCE_DROPDOWN_EP = 'Competence/GetCompetenceDropdowns';
export const CREATE_COMPETENCE_EP = 'Competence/CreateCompetence';
export const DELETE_COMPETENCE_EP = 'Competence/DeleteCompetence';
export const DELETE_TECH_INSTRUCTION_EP = 'PbsInstruction/DeleteInstructions';
export const READ_PMOL_SHORTCUT_PANE_EP = 'Pmol/ReadPmolShortcutPaneData';
export const READ_PMOL_LIST_EP = 'Pmol/FilterPmol';
export const READ_PMOL_DROPDOWN_EP = 'Pmol/GetPmolDropdownData';
export const READ_PMOL_DATA_EP = 'Pmol/readPmolData';
export const READ_PMOL_BY_ID_EP = 'Pmol/ReadPmolHeaderById/';
export const PMOL_SAVE_EP = 'Pmol/CreateHeader';
export const PMOL_SAVE_JOURNAL = 'PmolJournal/CreateJournal';
export const PMOL_GET_PMOL_JOURNAL = 'PmolJournal/ReadJournal/';
export const PMOL_LOCATION_EP = 'ProjectDefinition/ReadLocationByProjectId/';
export const READ_RESOURCE_BY_BOR_ID_EP = 'PmolResource/ReadBorResources/';
export const READ_BOR_MATERIALS_EP = 'BorResource/ReadMaterialByPbsProduct/';
export const READ_BOR_TOOLS_EP = 'BorResource/ReadMaterialByPbsProduct/';
export const READ_BOR_CONSUMABLE_EP = 'BorResource/ReadMaterialByPbsProduct/';
export const READ_BOR_LABOURS_EP = 'BorResource/ReadMaterialByPbsProduct/';
export const CREATE_LOCATION_EP = 'Pmol/CreateLocation';
export const START_HANDSHAKE_EP = 'Pmol/StartHandShake';
export const READ_PMOL_MATERIALS_EP = 'PmolResource/ReadMaterial/';
export const READ_PMOL_TOOLS_EP = 'PmolResource/ReadTools/';
export const READ_PMOL_CONSUMABLE_EP = 'PmolResource/ReadConsumable/';
export const READ_PMOL_LABOURS_EP = 'PmolResource/ReadLabour/';
export const SAVE_MATERIALS_EP = 'PmolResource/CreateMaterial';
export const SAVE_TOOLS_EP = 'PmolResource/CreateTools';
export const SAVE_CONSUMABLE_EP = 'PmolResource/CreateConsumable';
export const SAVE_LABOURS_EP = 'PmolResource/CreateLabour';
export const UPLOAD_SIGNATURE_EP = 'Pmol/UploadStopHandShakeFile';
export const STOP_HANDSHAKE_EP = 'Pmol/StopHandShake';
export const READ_STOP_HANDSHAKE_EP = 'Pmol/ReadStopHandShakesByPmolId?id=';
export const DELETE_PMOL_MATERIALS_EP = 'PmolResource/DeletePmolMaterial';
export const DELETE_PMOL_TOOLS_EP = 'PmolResource/DeletePmolTools';
export const DELETE_PMOL_CONSUMABLE_EP = 'PmolResource/DeletePmolConsumable';
export const DELETE_PMOL_LABOURS_EP = 'PmolResource/DeletePmolLabour';
export const READ_PMOL_EXTRA_WORK_MATERIALS_EP =
  'PmolResource/ReadExtraMaterial/';
export const READ_PMOL_EXTRA_WORK_TOOLS_EP = 'PmolResource/ReadExtraTools/';
export const READ_PMOL_EXTRA_WORK_CONSUMABLE_EP =
  'PmolResource/ReadExtraConsumable/';
export const READ_PMOL_EXTRA_WORK_LABOURS_EP = 'PmolResource/ReadExtraLabour/';
export const READ_PMOL_EXTRA_WORK_RESOURCES_EP =
  'PmolResource/ReadExtraResources/';
export const READ_PMOL_PLANNED_WORK_RESOURCES_EP =
  'PmolResource/ReadWebPlannedResources/';
export const SAVE_HANDSHAKE_DOCUMENTS_EP = 'Pmol/AddStopHandShakeDocuments';
export const UPLOAD_PMOL_EXTRA_WORK_FILE_EP = 'Pmol/UploadExtraworkFile';
export const READ_PMOL_EXTRA_WORK_EP = 'Pmol/ReadExtraWorkByPmolId/';
export const SAVE_EXTRA_WORK_EP = 'Pmol/ExtraWorkCreate';
export const GET_FOREMAN_BY_NAME_EP = 'CentralAddressBook/PersonFilterByName';
export const GET_FOREMAN_MOBILE_EP = 'CentralAddressBook/ReadMobileNumber/';
export const CLONE_PMOL_EP = 'Pmol/PmolClone/';
export const UPDATE_BOR_STATUS_EP = 'Bor/UpdateBorStatus';
export const CPC_PMOL_FILTER_EP = 'ContractingUnitTenants/PMOLCPCFilter';
export const CPC_SEARCH_PMOL_EP =
  'ContractingUnitTenants/GetProjectCpcByProjectSequenceCode?code=';
export const LOAD_PMOL_LABOUR_TEAM_MEMBERS_EP =
  'PmolResource/ReadPmolPlannedTeam/';
export const LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_EP =
  'PmolResource/ReadPmolExtraTeam/';
export const DELETE_PMOL_LABOUR_TEAM_MEMBERS_EP =
  'PmolResource/DeleteTeamMember';
export const READ_CPC_VEHICLES = 'CorporateProductCatalog/ReadVehiclesForQr/';
export const READ_PMOL_LOCATION_EP = 'Pmol/ReadLocationByPmolId/';
export const OPTIMIZE_DB_EP = 'Shift/OptimizeDatabase';
export const APPROVED_PMOL_EP = 'Pmol/ApprovePmol/';
export const FINISHED_PMOL_EP = 'Pmol/UpdateFinishedStatus/';
export const UPDATE_PMOL_LABOUR_END_TIME_EP = 'Pmol/UpdatePmolLabourEndTime';

export const GET_PROJECT_FOREMAN_BY_NAME_EP =
  'CentralAddressBook/ForemanFilterByName';
export const GET_PROJECT_TEAM_BY_NAME_EP =
  'CentralAddressBook/ForemanFilterByName';
export const GET_ORG_EP = 'ContractingUnitTenants/GetContractingUnits';
export const GET_CU_EP = 'ContractingUnitTenants/GetContractingUnitsById?';

export const READ_PS_SHORTCUT_PANE_EP =
  'ContractingUnitTenants/GetContractingUnitsById?';
export const READ_PS_DROPDOWN_EP = 'ProgressStatement/GetPsDropdownData';
export const READ_PS_LIST_EP = 'ProgressStatement/PsFilter';
export const READ_PS_BY_ID_EP = 'ProgressStatement/Read/';
export const PS_SAVE_EP = 'ProgressStatement/CreatePsHeader';
export const READ_COST_LIST_EP = 'ProjectCost/ProjectCostFilter';

export const PRICE_LIST_SAVE_EP = 'PriceList/CreateResourceTypePriceList';
export const PRICE_LIST_READ_EP = 'PriceList/ReadResourceTypePriceList';
export const RESOURCE_SAVE_EP = 'PriceList/CreateResourceItemPriceList';
export const PS_RESOURCE_MATERIALS_READ_EP = 'PriceList/ReadMaterialPriceList';
export const PS_RESOURCE_TOOLS_READ_EP = 'PriceList/ReadToolPriceList';
export const PS_RESOURCE_CONSUMABLES_READ_EP =
  'PriceList/ReadConsumablePriceList';
export const PS_RESOURCE_LABOUR_READ_EP = 'PriceList/ReadLabourPriceList';
export const RESOURCE_DELETE_EP = 'PriceList/DeleteResourceItemPriceList';
export const CREATE_PS_RESOURCE_EP = 'ProgressStatement/CreatePsResource';
export const APPROVED_PS_CU_EP = 'ProgressStatement/CUApprovePs/';
export const GET_THEME_EP = 'Tenant/Tenant';
export const APPROVED_PS_EP = 'ProgressStatement/ApprovePs/';
export const GET_VAT_EP = 'VAT/filter';
export const SAVE_VAT_EP = 'ProjectDefinition/UpdateVAT';

export const READ_INVOICE_SHORTCUT_PANE_EP =
  'ContractingUnitTenants/GetContractingUnitsById?';
export const READ_INVOICE_DROPDOWN_EP = 'ProgressStatement/GetPsDropdownData';
export const READ_INVOICE_LIST_EP = 'Invoice/FilterInvoice';
export const READ_INVOICE_BY_ID_EP = 'Invoice/ReadInVoiceById/';
export const INVOICE_SAVE_EP = 'ProgressStatement/CreatePsHeader';
export const APPROVED_INVOICE_CU_EP = 'Invoice/CUApprovePs/';
export const REVIEW_INVOICE_CU_EP = 'invoice/CUInReviewInvoice/';

export const REMOVE_PROJECTCOST = 'ProgressStatement/removeprojectcost/';

export const getAddressBookByIdURL = (id: string) => {
  return `CentralAddressBook/ReadCabPerson/${id}`;
};

export const getMaterialByProductIdURL = (id: string) => {
  return `PbsResource/ReadMaterialByPbsProduct/${id}`;
};

export const getToolsByProductIdURL = (id: string) => {
  return `PbsResource/ReadToolByPbsProduct/${id}`;
};

export const getLabourByProductIdURL = (id: string) => {
  return `PbsResource/ReadLabourByPbsProduct/${id}`;
};
export const getConsumableByProductIdURL = (id: string) => {
  return `PbsResource/ReadConsumableByPbsProduct/${id}`;
};

export const getPbsInstructionByType = (id: string, type: string) => {
  return `PbsInstruction/ReadInstructionsByPbsProduct?pbsId=${id}&instructionType=${type}`;
};

export const getPMOLInstructionByType = (id: string, type: string) => {
  return `PbsInstruction/GetAllInstructionsByPbsProductId?pbsId=${id}&instructionType=${type}`;
};

export const getPMOLRiskesByPBSId = (pbsProductId: string) => {
  return `PbsRisk/GetAllRiskByPbsProductId/${pbsProductId}`;
};

export const getPMOLQualityByPBSId = (pbsProductId: string) => {
  return `PbsQuality​/GetAllQualityByPbsProductId​/${pbsProductId}`;
};

export const PBS_CREATE_SERVICE = 'PbsResource/CreatePbsService';
export const PMOL_CREATE_SERVICE = 'Pmol/CreatePmolService';
export const PBS_READ_SERVICE = 'PbsResource/ReadServiceByPbsProduct/';
export const PMOL_READ_SERVICE = 'Pmol/ReadPmolServiceByPmolId/';
export const PBS_CREATE_SCOPE_OF_WORK_EP = 'Pbs/CreatePbsScopeOfWork/';

export const PBS_READ_SHORTCUT_PANE_DATA = 'Pbs/ReadPbsShortcutPaneData';
export const PBS_READ_LIST_PANE_DATA = 'PbsFilter/PbsFilter';
export const PBS_SAVE = 'Pbs/Create';
export const PBS_GET_BY_ID = 'Pbs/Read/';
export const READ_DROPDOWN_DATA = 'Pbs/GetPbsDropdownData';
export const READ_TAXONOMY_DATA = 'Pbs/ReadTreeStructureData';
export const DELETE_PBS_EP = 'Pbs/Delete';
export const READ_PBS_RELATIONS_EP = 'Pbs/GetPbsRelations/';
export const READ_CPC_RELATIONS_EP = 'Pbs/GetCpcRelations';

export const READ_TAXONOMY_LEVEL_EP = 'Pbs/ReadTaxonomyLevels';
export const CREATE_TAXONOMY_NODE_EP = 'Pbs/CreateNode';
export const CLONE_PBS_EP = 'Pbs/Clone';
export const SAVE_PBS_COMPOSITION_IMAGES = 'pbs/CreateProductComposition';
export const getNoCabCompaniesURL = (name: string) => {
  if (name) {
    return `CentralAddressBook/GetUnassignedCompanyList?name=${name}`;
  }
  return 'CentralAddressBook/GetUnassignedCompanyList';
};

export const BOR_READ_SHORTCUT_PANE_DATA = 'Bor/ReadBorShortcutPaneData';
export const BOR_READ_LIST_PANE_DATA = 'Bor/FilterBor';
export const BOR_READ_RESOURCE_LIST_PANE_DATA = 'Bor/FilterBorResource';
export const BOR_SAVE = 'Bor/Create';
export const PBS_BOR_BY_ID = 'Bor/ReadBor/';
export const READ_BOR_DROPDOWN_DATA_EP = 'Bor/GetBorDropdownData';
export const BOR_READ_BY_PRODUCT_DATA = 'Bor/GetBorListByProduct';
export const UPDATE_BOR_MATERIAL_EP = 'BorResource/UpdateBorMaterial';
export const UPDATE_BOR_TOOLS_EP = 'BorResource/UpdateBorTools';
export const UPDATE_BOR_CONSUMABLE_EP = 'BorResource/UpdateBorConsumable';
export const UPDATE_BOR_LABOURS_EP = 'BorResource/UpdateBorLabour';
export const DELETE_BOR_MATERIALS_EP = 'BorResource/DeleteBorMaterial';
export const DELETE_BOR_TOOLS_EP = 'BorResource/DeleteBorTools';
export const DELETE_BOR_CONSUMABLE_EP = 'BorResource/DeleteBorConsumable';
export const DELETE_BOR_LABOURS_EP = 'BorResource/DeleteBorLabour';

export const PBS_PRODUCT_BY_ID = 'Bor/ReadProductById/';
export const PBS_MATERIALS_EP = 'BorResource/ReadMaterialByPbsProduct/';
export const PBS_TOOLS_EP = 'BorResource/ReadToolByPbsProduct/';
export const PBS_CONSUMABLE_EP = 'BorResource/ReadConsumableByPbsProduct/';
export const PBS_LABOURS_EP = 'BorResource/ReadLabourByPbsProduct/';
export const CPC_BY_ID_EP = 'CorporateProductCatalog/ReadCpcDetails/';
export const DELETE_BOR_EP = 'Bor/Delete';

// ProjectDefinition
export const READ_LAST_SEEN_PROJECTS =
  'ProjectDefinition/ReadLastSeenProjects/';
export const PROJECT_DEFINITION_READ_EP = 'ProjectDefinition/ReadById/';
export const PROJECT_HEADER_DETAILS_READ_EP =
  'ProjectDefinition/ReadProjectDataById/';
export const PROJECT_DEFINITION_ADD_PROJECT_CONFIGURATION_EP =
  'ProjectDefinition/AddProjectConfiguration';
export const WORK_FLOW_STATE_EP = 'WorkflowState/GetWorkflowStates';

export const QR_CODE_READ_EP = 'QrCode/ReadById/';

export const CPC_READ_DROPDOWN_DATA_EP =
  'CorporateProductCatalog/GetCpcDropdownData';
export const CPC_READ_SHORTCUT_PANE_DATA =
  'CorporateProductCatalog/ReadCpcShortcutPaneData';
export const CPC_CREATE_EP = 'CorporateProductCatalog/CreateCpc';

export const PROJECT_FILTER_EP = 'ProjectDefinition/ProjectFilter';
export const DELETE_QRCODE_EP = 'QrCode/Delete/';

// Language
export const GET_LANGUAGES_EP = 'Language/getLanguage';
export const LANGUAGE_GET_BY_ID_EP = 'Language/GetLanguageById/';
export const GET_KEY_WORD_EP = 'WebTranslation/getKeyWord/';
export const CREATE_LANGUAGE_EP = '';
export const CREATE_TRANSLATION_EP = 'WebTranslation/AddWebTranslation';
export const UPDATE_LANGUAGE_EP = 'Language/UpdateLanguage';
export const ADD_LANGUAGE_EP = 'Language/AddLanguage';
export const UPDATE_TRANSLATION_EP = 'WebTranslation/UpdateWebTranslation';

// WAREHOUSE
export const WAREHOUSE_SAVE_EP = 'WareHouse/CreateHeader';
export const READ_WAREHOUSE_BY_ID_EP = 'WareHouse/GetById/';
export const READ_WAREHOUSE_DROPDOWN_EP = 'WareHouse/GetWHDropdown';
export const READ_WAREHOUSE_LIST_EP = 'WareHouse/FilterWH';
export const READ_WAREHOUSE_SHORTCUT_PANE_EP = 'Stock/ShortcutPaneData';
export const CREATE_WAREHOUSE_LOCATION_EP = 'PO/CreateLocation';
export const CREATE_WAREHOUSE_TAXONOMY_NODE_EP = 'WareHouse/CreateWHTaxonomy';
export const LOAD_WAREHOUSE_TAXONOMY_NODE_EP = 'WareHouse/GetWHTaxonomyList';

export const WH_TYPE_DROPDOWN_BY_CODE_EP = '/DropDown/GetWHTypeByCode/';
export const WH_TYPE_DROPDOWN_CREATE_EP = 'DropDown/AddWHType';
export const WH_TYPE_DROPDOWN_DELETE_EP = 'DropDown/DeleteWHType/';

export const WH_STATUS_DROPDOWN_BY_CODE_EP = '/DropDown/GetWHStatusByCode/';
export const WH_STATUS_DROPDOWN_CREATE_EP = 'DropDown/AddWHStatus';
export const WH_STATUS_DROPDOWN_DELETE_EP = 'DropDown/DeleteWHStatus/';

export const WH_TAXONOMY_LEVEL_DROPDOWN_BY_CODE_EP =
  '/DropDown/GetWHTaxonomyLevelByCode/';
export const WH_TAXONOMY_LEVEL_DROPDOWN_CREATE_EP =
  'DropDown/AddWHTaxonomyLevel';
export const WH_TAXONOMY_LEVEL_DROPDOWN_DELETE_EP =
  'DropDown/DeleteWHTaxonomyLevel/';

// Purchase Order
export const READ_PURCHASE_ORDER_SHORTCUT_PANE_EP = 'PO/ShortcutPaneData';
export const READ_PURCHASE_ORDER_LIST_EP = 'PO/FilterPO';
export const READ_PURCHASE_ORDER_DROPDOWN_EP = 'PO/GetPoDropdownData';
export const READ_PURCHASE_ORDER_BY_ID_EP = 'PO/ReadPOHeaderById/';
export const PURCHASE_ORDER_SAVE_EP = 'PO/CreateHeader';
export const BOR_RESOURCE_GETBY_ID_EP = 'Bor/BorResourcesbyIds';
export const PBS_RESOURCE_GETBY_ID_EP = 'PbsFilter/ProductResourcesById/';
export const PBS_CBC_UPLOAD_EP = 'Pbs/ExcelUpload';
export const LOT_CBC_UPLOAD_EP = 'Contractor/ExcelUploadTest';
export const CONVERT_CR_TO_PO_EP = 'PO/ConvertToPo';
export const APPROVED_PO_EP = 'PO/PMApprove';
export const ACCEPT_PO_EP = 'PO/PMAccept';
export const APPROVED_PO_CU_EP = 'PO/CUApprove';
export const APPROVED_PO_CU_LEVEL_EP = 'PO/CULevelApprove';
export const FEEDBACK_PO_CU_EP = 'PO/CUFeedback';
export const PROJECT_SEND_PO_EP = 'PO/ProjectSend';
export const CU_SEND_PO_EP = 'PO/CUSend';
export const CREATE_PO_LOCATION_EP = 'PO/CreateLocation';
export const LOAD_DELIVERY_PLAN_EP = 'PO/POResourceFilter';
export const LOAD_CU_DELIVERY_PLAN_EP = 'PO/CUPOResourceFilter';
export const LOAD_PRODUCT_DELIVERY_PLAN_EP = 'PO/POResourceFilter';
export const DELIVERY_PLAN_STOCK_UPDATE_EP = 'PO/POResourceStockUpdate';
export const CU_DELIVERY_PLAN_STOCK_UPDATE_EP = 'PO/CUPOResourceStockUpdate';
export const DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_EP = 'PO/POProductIsPoUpdate';
export const DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_EP =
  'PO/CUPOProductIsPoUpdate';
export const LOAD_BOR_PO_LIST_PANE_LIST_EP = 'Bor/FilterBorPo';
export const LOAD_BOR_RETURN_PO_LIST_PANE_LIST_EP = 'Bor/FilterReturnBorPo';
export const LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_EP = 'Po/POBorResourceFilter';
export const LOAD_PRODUCT_PO_LIST_PANE_LIST_EP = 'PbsFilter/PbsFilterPO';
export const LOAD_CU_PRODUCT_PO_LIST_PANE_LIST_EP = 'PO/POPBSResourceFilter';
export const LOAD_PO_PROJECT_LIST_EP = 'PO/POProjectList';
export const LOAD_PO_BOR_UPDATE_EP = 'PO/POBorUpdate';
export const LOAD_PO_CU_BOR_UPDATE_EP = 'PO/CUPOBorUpdate';
export const SEND_RFQ_EMAIL_EP = 'RFQ/SendRfqEmail';
export const LOAD_RFQ_INVITATION_DETAILS_EP = 'RFQ/ReadRfqById/';
export const SAVE_SIGNATURE_EP = 'RFQ/AcceptRfqEmail';
export const UPLOAD_RFQ_SIGNATURE_EP = 'RFQ/UploadRfqSignature';

// workflow
export const READ_WORK_FLOW_BY_ID_EP = 'WorkFlow/GetById/';
export const READ_WORK_FLOW_DROPDOWN_EP = 'WorkFlow/GetWFDropdown';
export const READ_WORK_FLOW_LIST_EP = 'WorkFlow/FilterWF';
export const READ_WORK_FLOW_SHORTCUT_PANE_EP = 'PO/ShortcutPaneData';
export const WORK_FLOW_SAVE_EP = 'WorkFlow/CreateHeader';
export const APPROVE_WORK_FLOW_EP = 'WorkFlow/CUApprove/';
export const APPROVE_WORK_FLOW_PROJECT_EP = 'WorkFlow/ProjectApprove/';

export const WF_STATUS_DROPDOWN_BY_CODE_EP = 'DropDown/GetWFStatusByCode/';
export const WF_STATUS_DROPDOWN_CREATE_EP = 'DropDown/AddWFStatus';
export const WF_STATUS_DROPDOWN_DELETE_EP = 'DropDown/DeleteWFStatus/';

export const WF_TYPES_DROPDOWN_BY_CODE_EP = 'DropDown/GetWFTypeByCode/';
export const WF_TYPES_DROPDOWN_CREATE_EP = 'DropDown/AddWFType';
export const WF_TYPES_DROPDOWN_DELETE_EP = 'DropDown/DeleteWFType/';

// ---dropdownLanguage
export const GET_LANGUAGES_DISTINCT_EP = 'Language/getLanguageDistinct';
export const GET_COUNTRY_DISTINCT_EP = 'Language/getCountryDistinct';
export const GET_LANGUAGE_BY_CODE_EP = 'Language/GetLanguageByCode?code=';
// CPC-MOU
export const GET_DROPDOWN_KEYS_BY_CODE_EP =
  'CpcBasicUnitOfMeasureLocalizedData/GetByCode?code=';
export const DROPDOWN_KEYS_UPDATE_EP =
  'CpcBasicUnitOfMeasureLocalizedData/Update';
export const CPC_MOU_DROPDOWN_KEYS_CREATE_EP =
  'CpcBasicUnitOfMeasureLocalizedData/Add';
export const CPC_MOU_DROPDOWN_DELETE_EP =
  '/CpcBasicUnitOfMeasureLocalizedData/Delete/';

// CPC-ResourceFamily
export const GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_EP = '';
export const CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_EP = '';
export const CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_EP = '';
export const CPC_RESOURCE_FAMILY_DROPDOWN_DELETE_EP = '';

// CPC-ResourceType
export const GET_CPC_RESOURCE_TYPES_BY_CODE_EP =
  'CpcResourceTypeLocalizedData/GetByCode?code=';
export const CPC_RESOURCE_TYPE_UPDATE_EP =
  'CpcResourceTypeLocalizedData/Update';
export const CPC_RESOURCE_TYPE_CREATE_EP = 'CpcResourceTypeLocalizedData/Add';
export const CPC_RESOURCE_TYPE_DELETE_EP = '';

// CPC-Material
export const GET_CPC_MATERIAL_BY_CODE_EP =
  'CpcMaterialLocalizedData/GetByCode?code=';
export const CPC_MATERIAL_UPDATE_EP = 'CpcMaterialLocalizedData/Update';
export const CPC_MATERIAL_CREATE_EP = 'CpcMaterialLocalizedData/Add';
export const CPC_MATERIAL_DELETE_EP = 'CpcMaterialLocalizedData/Delete/';

// CPC-Presure
export const GET_CPC_PRESSURE_DROPDOWN_BY_CODE_EP = '';
export const CPC_PRESSURE_DROPDOWN_UPDATE_EP = '';
export const CPC_PRESSURE_DROPDOWN_CREATE_EP = '';
export const CPC_PRESSURE_DROPDOWN_DELETE_EP = '';

// CPC-UnitOfSizeMeasure
export const GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_EP = '';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_EP = '';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_EP = '';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_DELETE_EP = '';

// CPC-Brand
export const GET_CPC_BRAND_DROPDOWN_BY_CODE_EP =
  'CpcBrandLocalizedData/GetByCode?code=';
export const CPC_BRAND_DROPDOWN_UPDATE_EP = 'CpcBrandLocalizedData/Update';
export const CPC_BRAND_DROPDOWN_CREATE_EP = '';
export const CPC_BRAND_DROPDOWN_DELETE_EP = 'CpcBrandLocalizedData/Delete/';

// Project-ManagementLevel
export const GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_EP = '';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_EP = '';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_DELETE_EP = '';

// Projetc-Currencies
export const GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_CURRENCIES_DROPDOWN_UPDATE_EP = '';
export const PROJECT_CURRENCIES_DROPDOWN_CREATE_EP = '';
export const PROJECT_CURRENCIES_DROPDOWN_DELETE_EP = '';

// Project-ToleranceState

// Project-States
export const GET_PROJECT_STATES_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_STATES_DROPDOWN_UPDATE_EP = '';
export const PROJECT_STATES_DROPDOWN_CREATE_EP = '';
export const PROJECT_STATES_DROPDOWN_DELETE_EP = '';

// Project-Types
export const GET_PROJECT_TYPES_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_TYPES_DROPDOWN_UPDATE_EP = '';
export const PROJECT_TYPES_DROPDOWN_CREATE_EP = '';
export const PROJECT_TYPES_DROPDOWN_DELETE_EP = '';

// Project-Templates
export const GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_TEMPLATES_DROPDOWN_UPDATE_EP = '';
export const PROJECT_TEMPLATES_DROPDOWN_CREATE_EP = '';
export const PROJECT_TEMPLATES_DROPDOWN_DELETE_EP = '';

// Project-Ledger
export const GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_EP = '';
export const PROJECT_LEDGER_DROPDOWN_UPDATE_EP = '';
export const PROJECT_LEDGER_DROPDOWN_CREATE_EP = '';
export const PROJECT_LEDGER_DROPDOWN_DELETE_EP = '';

// PBS-Tolerance
export const GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_EP =
  'PbsToleranceStateLocalizedData/GetByCode?code=';
export const PBS_TOLERANCE_DROPDOWN_UPDATE_EP =
  'PbsToleranceStateLocalizedData/Update';
export const PBS_TOLERANCE_DROPDOWN_CREATE_EP =
  'PbsToleranceStateLocalizedData/Add';
export const PBS_TOLERANCE_DROPDOWN_DELETE_EP = '';

// PBS-ProductStatus
export const GET_PBS_STATUS_DROPDOWN_BY_CODE_EP =
  'PbsProductStatusLocalizedData/GetByCode?code=';
export const PBS_STATUS_DROPDOWN_UPDATE_EP =
  'PbsProductStatusLocalizedData/Update';
export const PBS_STATUS_DROPDOWN_CREATE_EP =
  'PbsProductStatusLocalizedData/Add';
export const PBS_STATUS_DROPDOWN_DELETE_EP = '';

// PBS-ItemType
export const GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_EP =
  'PbsProductItemTypeLocalizedData/GetByCode?code=';
export const PBS_ITEM_TYPE_DROPDOWN_UPDATE_EP =
  'PbsProductItemTypeLocalizedData/Update';
export const PBS_ITEM_TYPE_DROPDOWN_CREATE_EP =
  'PbsProductItemTypeLocalizedData/Add';
export const PBS_ITEM_TYPE_DROPDOWN_DELETE_EP = '';

// PBS-Experience
export const GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_EP =
  'PbsExperienceLocalizedData/GetByCode?code=';
export const PBS_EXPERIENCE_DROPDOWN_UPDATE_EP =
  'PbsExperienceLocalizedData/Update';
export const PBS_EXPERIENCE_DROPDOWN_CREATE_EP =
  'PbsExperienceLocalizedData/Add';
export const PBS_EXPERIENCE_DROPDOWN_DELETE_EP = '';

// PBS-Skill
export const GET_PBS_SKILLS_DROPDOWN_BY_CODE_EP = '';
export const PBS_SKILLS_DROPDOWN_UPDATE_EP = '';
export const PBS_SKILLS_DROPDOWN_CREATE_EP = '';
export const PBS_SKILLS_DROPDOWN_DELETE_EP = '';

// PMOL-Status
export const GET_PMOL_STATUS_DROPDOWN_BY_CODE_EP = '';
export const PMOL_STATUS_DROPDOWN_UPDATE_EP = '';
export const PMOL_STATUS_DROPDOWN_CREATE_EP = '';
export const PMOL_STATUS_DROPDOWN_DELETE_EP = '';

// PMOL-Type
export const GET_PMOL_TYPE_DROPDOWN_BY_CODE_EP = '';
export const PMOL_TYPE_DROPDOWN_UPDATE_EP = '';
export const PMOL_TYPE_DROPDOWN_CREATE_EP = '';
export const READ_PMOL_BY_ID = 'Pmol/ReadPmolId/';
export const READ_PMOL_PDF_BY_ID = 'Pmol/ReadPmolId/';
export const PMOL_TYPE_DROPDOWN_DELETE_EP = '';

// BOR-Status
export const GET_BOR_STATUS_DROPDOWN_BY_CODE_EP = '';
export const BOR_STATUS_DROPDOWN_UPDATE_EP = '';
export const BOR_STATUS_DROPDOWN_CREATE_EP = '';
export const BOR_STATUS_DROPDOWN_DELETE_EP = '';

// Stock
export const STOCK_SAVE_EP = 'Stock/CreateHeader';
export const READ_STOCK_BY_ID_EP = 'Stock/GetById/';
export const READ_STOCK_DROPDOWN_EP = 'Stock/GetStockDropdown';
export const READ_STOCK_LIST_EP = 'Stock/FilterStock';
export const READ_STOCK_SHORTCUT_PANE_EP = 'Stock/ShortcutPaneData';

export const READ_ALL_INSTRUCTIONS =
  'PbsInstruction/GetAllPbsInstructionsByPbsProductAllTypes?pbsId=';

// Project-Role
export const PROJECT_ROLE_EP = 'User/getRoles';
export const PROJECT_ROLE_DROPDOWN_BY_CODE_EP =
  'DropDown/GetProjectRoleByCode/';
export const PROJECT_TEAMROLE_CREATE_EP = 'DropDown/AddProjectRole';
export const PROJECT_TEAMROLE_DELETE_EP = 'DropDown/DeleteProjectRole/';

// Stock-Resource Type
export const STOCK_RESOURCETYPE_DROPDOWN_BY_CODE_EP =
  'DropDown/GetStockResourceByCode/';
export const STOCK_RESOURCETYPE_DROPDOWN_CREATE_EP =
  'DropDown/AddStockResource';
export const STOCK_RESOURCETYPE_DROPDOWN_DELETE_EP =
  'DropDown/DeleteStockResource/';

// Stock-Type
export const STOCK_TYPE_DROPDOWN_READ_EP = 'DropDown/GetStockTypeByCode/';
export const STOCK_TYPES_DROPDOWN_CREATE_EP = 'DropDown/AddStockType';
export const STOCK_TYPES_DROPDOWN_DELETE_EP = 'DropDown/DeleteStockType/';

// Stock-Status
export const STOCK_STATUS_DROPDOWN_READ_EP = 'DropDown/GetStockStatusByCode/';
export const STOCK_STATUS_DROPDOWN_CREATE_EP = 'DropDown/AddStockStatus';
export const STOCK_STATUS_DROPDOWN_DELETE_EP = 'DropDown/DeleteStockStatus/';

// Visual Plan
export const VISUAL_PLAN_SAVE_EP = '';
export const READ_VISUAL_PLAN_BY_ID_EP = '';
export const READ_VISUAL_PLAN_DROPDOWN_EP = '';
export const ADD_PO_LABOUR_TEAM_EP = 'PO/AddPoLabourTeam';
export const UPDATE_VP_PO_EP = 'VP/UpdateVpPo';
export const UPDATE_PERSON_DATES_EP = 'VP/UpdatePersonDate';
export const ADD_PO_TOOLS_POOL_EP = 'PO/AddPoToolPool';
export const READ_CUHR_BY_PERSON_ID_EP = 'VP/VpHRByPersonId/';
export const UPDATE_WH_TAXONOMY_DATE = 'VP/UpdateWHTaxonomyDate';
export const READ_VP_PROJECT_RESOURCE = 'VP/GeTLabourTeamsAndToolsByDate';
export const READ_VP_PROJECT_SHORTCUT = 'VP/GetMilestoneShortcutPaneData';
export const UPDATE_PMOL_VP_LABOUR_EP = 'VP/AddPmolPlannedLabour';
export const UPDATE_PMOL_VP_TOOL_EP = 'VP/AddPmolPlannedTools';
export const UPDATE_VP_ITEM_DATE = 'vp/UpdateBorPbsForVp';
export const GET_VP_FILTER_DROPDOWN_DATA_EP = 'vp/GetVPFilterDropdownData';
export const CREATE_PBS_CLONE_EP = 'VP/PbsCloneForVp';
export const CREATE_PR_FROM_BOR_EP = 'VP/CretePrFromBor';
export const READ_RESOURCE_ITEMS_VP = 'VP/GetResourceItemsForVp';
export const DELETE_VP_SHIFT_RESOURCE_ITEM_EP = 'VP/DeleteVpLabourAssign/';
export const GET_PBS_LABOUR = 'Pbs/GetPbsLabour/';
export const CREATE_PMOL_PBS_EP = 'Pbs/CreatePmol'; // create pmol using PBS
export const GET_ALL_PBS_LABOUR_EP = 'Pbs/GetAllPbsLabour';
export const GET_ALL_PMOL_LABOUR_EP = 'Pbs/GetAllPmolLabour';
export const GET_RESOURCE_MATRIX = 'ResourceMatrix/GetResourceMatrix';
export const GET_MIDTERM_CAPACITY_DATA = 'ResourceMatrix/GetLabourMatrix';
export const GET_PROJECT_TEAM_CAPACITY_DATA =
  'ResourceMatrix/GetOrganizationMatrix';

export const READ_VP_PROJECT_DATA = 'VP/GetPbsForVP';

// export const READ_VISUAL_PLAN_LIST_EP = 'VP/GetVpPo';
export const READ_VISUAL_PLAN_LIST_EP = 'VP/VpPo';
export const READ_VISUAL_PLAN_TOOLS_LIST_EP = 'VP/GetVpWH';
export const READ_VISUAL_PLAN_WAREHOUSE_TOOLS_LIST_EP = 'VP/GetVpWHTool';
export const READ_CPC_RESOURCE_FAMILY_FOR_VP_EP =
  'CpcResourceFamily/ReadCpcResourceFamilyForVP';
export const READ_VISUAL_PLAN_SHORTCUT_PANE_EP = 'VP/VpShortcutPaneData';
export const READ_VP_ORG_SETTING_EP = 'VP/GetVpOrganizationTaxonomyList';
// ORG SETTING
export const ORG_SETTING_SAVE_EP = 'OrganizationSettings/CreateOrganization';
export const READ_ORG_SETTING_BY_ID_EP =
  'OrganizationSettings/OrganizationGetById/';
// export const READ_ORG_SETTING_DROPDOWN_EP = '';
export const READ_ORG_SETTING_LIST_EP =
  'OrganizationSettings/GetOrganizationTaxonomyList';
export const READ_ORG_SETTING_SHORTCUT_PANE_EP = 'VP/ShortcutPaneData';
export const READ_ORG_SETTING_TAXONOMY_LEVEL_EP =
  'OrganizationSettings/GetOrganizationTaxonomyLevel';
export const CREATE_ORG_SETTING_TAXONOMY_EP =
  'OrganizationSettings/CreateOrganizationTaxonomy';
export const DELETE_ORG_SETTING_TAXONOMY_EP =
  'OrganizationSettings/DeleteOrganizationTaxonomyNode/';
export const READ_ORG_PERSON_TYPE_DROPDOWN_EP =
  'OrganizationSettings/GetOrganizationDropdown';
export const ORG_PERSON_FILTER_EP = 'CentralAddressBook/PersonFilter';
export const SET_ORG_BU_IS_DEFAULT_EP =
  'OrganizationSettings/OrganizationTaxonomySetDefaultBu';

// Competency
export const COMPETENCY_SAVE_EP =
  'OrganizationSettings/CreateOrganizationCompetencies';
export const CREATE_COMPETANCY_TAXONOMY_EP =
  'OrganizationSettings/CreateCompetenceTaxonomy';
export const READ_COMPETENCY_BY_ID_EP =
  'OrganizationSettings/OrganizationCompetenceGetById/';
export const READ_COMPETENCY_DROPDOWN_EP =
  'OrganizationSettings/GetOrganizationCompetenceDropdown';
export const READ_COMPETENCY_LIST_EP =
  'OrganizationSettings/GetOrganizationCompetenceList';
// export const READ_COMPETENCY_SHORTCUT_PANE_EP = '';
export const READ_COMPETENCY_TAXONOMY_LIST_EP =
  'OrganizationSettings/GetCompetenceTaxonomyList';

// Certification
export const CERTIFICATION_SAVE_EP =
  'OrganizationSettings/CreateOrganizationCertification';
export const READ_CERTIFICATION_BY_ID_EP =
  'OrganizationSettings/OrganizationCertificationGetById/';
export const READ_CERTIFICATION_DROPDOWN_EP =
  'OrganizationSettings/GetOrganizationCertificationDropdown';
export const READ_CERTIFICATION_LIST_EP =
  'OrganizationSettings/GetCertificationTaxonomyList';
// export const READ_CERTIFICATION_SHORTCUT_PANE_EP = '';
export const READ_CERTIFICATION_TAXANOMY_EP =
  'OrganizationSettings/GetCertificationTaxonomyList';
export const CREATE_CERTIFICATION_TAXONOMY_EP =
  'OrganizationSettings/CreateCertificationTaxonomy';

// Corporate Schedule
export const CORPORATE_SCHEDULE_SAVE_EP =
  'OrganizationSettings/CreateCorporateShedule';
export const READ_CORPORATE_SCHEDULE_BY_ID_EP =
  'OrganizationSettings/CorporateSheduleGetById/';
// export const READ_CORPORATE_SCHEDULE_DROPDOWN_EP = '';
export const READ_CORPORATE_SCHEDULE_LIST_EP =
  'OrganizationSettings/GetCorporateSheduleList';
export const READ_CORPORATE_SCHEDULE_SHORTCUT_PANE_EP = '';

// Organisation Configuration CAB-Competencies
export const CREATE_CAB_COMPETENCIES_EP =
  'CentralAddressBook/CreateCabCompetencies';
export const READ_CAB_COMPETENCIES_EP =
  'CentralAddressBook/GetCabCompetencies/';
export const DELETE_CAB_COMPETENCIES_EP =
  'CentralAddressBook/DeleteCabCompetencies';

// Organisation Configuration CAB-Certifications
export const CREATE_CAB_CERTIFICATION_EP =
  'CentralAddressBook/CreateCabCertification';
export const READ_CAB_CERTIFICATION_EP =
  'CentralAddressBook/GetCabCertification/';
export const DELETE_CAB_CERTIFICATION_EP =
  'CentralAddressBook/DeleteCabCertification';

// Human Resource
export const HUMAN_RESOURCE_SAVE_EP = 'HR/CreateHR';
export const READ_HUMAN_RESOURCE_BY_ID_EP = 'HR/GetHRById/';
export const READ_HUMAN_RESOURCE_DROPDOWN_EP = '';
export const READ_HUMAN_RESOURCE_LIST_EP = 'HR/FilterHRList';
export const READ_HUMAN_RESOURCE_SHORTCUT_PANE_EP = '';
export const READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_EP =
  'HR/GetTaxonomyIdByPersonId/';
export const GET_HUMAN_RESOURCE_ROLES_DROPDOWN_EP = 'HR/GetHRRoles';
export const READ_LABOUR_HISTORY_BY_PERSON_ID_EP = 'HR/GetLabourHistory/';
export const HR_PERSON_FILTER_EP = 'HR/HRPersonFilter';
export const DELETE_HUMAN_RESOURCE_EP = 'HR/delete/';
export const LOAD_ASSIGNED_HUMAN_RESOURCES_EP = 'HR/GetLabourPmolPr/';
export const LOAD_HR_CERTIFICATIONS_EP = 'HR/GetWorkersCertifications'

// Absence
export const CREATE_ABSENCE_EP = 'Absence/CreateAbsence';
export const READ_ABSENCE_EP = 'Absence/GetAbsenceById/';
export const READ_ABSENCE_LIST_BY_PERSON_EP =
  'Absence/GetAbsenceListByPersonId/';
export const READ_CONTRACT_LIST_BY_PERSON_EP = 'HR/GetHrContractorList/';
export const CREATE_CONTRACT_EP = 'HR/CreateHrContractorList';
export const DELETE_CONTRACT_EP = 'HR/DeleteHrContractorList';
export const DELETE_ABSENCE_EP = 'Absence/DeleteAbsence';

// day planning
export const DAY_PLANNINNG_SAVE_EP =
  'CentralAddressBook/CreateCabCertification';
export const READ_DAY_PLANNINNG_BY_ID_EP =
  'CentralAddressBook/GetCabCertification/';
export const READ_DAY_PLANNINNG_DROPDOWN_EP =
  'CentralAddressBook/DeleteCabCertification';
export const READ_DAY_PLANNINNG_PMOL_LIST_EP = 'VP/GetPmol';
export const READ_DAY_PLANNINNG_REOSURCE_LIST_EP = 'VP/GetAvailableTeams';
export const READ_DAY_PLANNINNG_TEAMS_LIST_EP = 'MyCalender/MyCalenderListData'; //'VP/GetPmolTeams'
export const READ_DAY_PLANNINNG_SHORTCUT_PANE_EP =
  'CentralAddressBook/DeleteCabCertification';
export const DND_ITEM_TO_PMOL_EP = 'VP/UpdatePMOL';
export const GET_ABSENCE_LEAVETYPE_DROPDOWN_EP = 'Absence/GetAbsenceLeaveType';
export const GET_CONTRACT_TYPE_DROPDOWN_EP = 'HR/GetHrContractTypes';
export const UPLOAD_CONTRACT_DOC_EP = 'HR/HrContractorFileUpload';
export const READ_MY_CALENDER_PBS_TAXONOMY_EP =
  'MyCalender/GetMyCalenderPbsTaxonomy/';
export const CREATE_MY_CALENDAR_NEW_PMOL_EP = 'MyCalender/MyCalenderCreatePmol';
export const GET_MY_CALENDAR_ORGANIZATION_TAXONOMY_EP =
  'OrganizationSettings/GetOrganizationTaxonomyListForMyCalender';
export const READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_EP =
  'MyCalender/MyCalenderListDataForCu';

// milestone
export const MILESTONES_SAVE_EP = 'VP/CreateMilestone';
export const READ_MILESTONES_BY_ID_EP = 'VP/GetMilestoneById/';
export const READ_MILESTONES_DROPDOWN_EP = 'VP/GetMilestoneDropdown';
export const READ_MILESTONES_LIST_EP = 'VP/MilestoneList';
export const READ_MILESTONES_SHORTCUT_PANE_EP = '';
export const CREATE_MACHINE_TAXONOMY_NODE_EP = 'VP/CreateMachineTaxonomy';

export const READ_VP_PRODUCT_TAXONOMY_DATA = 'VP/GetPbsTreeStructureDataForVp';
export const READ_VP_PURCHASE_TAXONOMY_DATA = 'VP/GetBorForProjectPlanning';
export const READ_VP_PURCHASE_TAXONOMY_CU_DATA = 'VP/GetBorForVp';
export const READ_VP_PURCHASE_PR_TAXONOMY_DATA = 'VP/GetPoForProjectPlanning';
export const READ_VP_PMOL_PERSON_DATA = 'VP/GetPbswithPmol';
export const UPDATE_PBS_TAXONOMY_PARENT =
  'VP/UpdatePbsProductTaxonomyDataForVp';
export const READ_PBS_UTILITY_TAXONOMY_VP =
  'Pbs/GetUtilityTaxonomyForProjectPlanning';
export const READ_PBS_LOCATION_TAXONOMY_VP =
  'Pbs/GetLocationTaxonomyForProjectPlanning';
export const READ_PBS_MACHINE_TAXONOMY_VP =
  'Pbs/GetMachineTaxonomyForProjectPlanning';

// risk-register
export const RISK_REGISTER_SAVE_EP = 'Risk/createRisk';
export const READ_RISK_REGISTER_BY_ID_EP = 'Risk/ReadRisk/';
export const READ_RISK_REGISTER_DROPDOWN_EP = 'Risk/ReadRiskDropdowns';
export const READ_RISK_REGISTER_LIST_EP = 'Risk/RiskFilter';
export const READ_RISK_REGISTER_SHORTCUT_PANE_EP = '';

// quality -DPH
export const QUALITY_SAVE_EP = 'Quality/CreateQuality';
export const READ_QUALITY_BY_ID_EP = 'Quality/ReadQuality/?id=';
export const READ_QUALITY_DROPDOWN_EP = '';
//export const READ_QUALITY_LIST_EP = 'Quality/ReadQualityList';
export const READ_QUALITY_LIST_EP = 'Quality/QualityFilter';
export const READ_QUALITY_SHORTCUT_PANE_EP = '';
export const DELETE_QUALITYBY_EP = 'Quality/DeleteQuality';

// instruction-register
export const INSTRUCTION_REGISTER_SAVE_EP = 'PbsInstruction/CreateInstructions';
export const READ_INSTRUCTION_REGISTER_BY_ID_EP =
  'PbsInstruction/ReadInstructions/';
export const READ_INSTRUCTION_REGISTER_DROPDOWN_EP =
  'PbsInstruction/ReadInstructionDropdowns';
export const READ_INSTRUCTION_REGISTER_LIST_EP =
  'PbsInstruction/InstructionsFilter';
export const UPLOAD_INSTRUCTION_REGISTER_DOC_EP =
  'PbsInstruction/UploadDocument';
export const READ_INSTRUCTION_REGISTER_SHORTCUT_PANE_EP = '';

//CBC
export const ORANISATION_CBC_SAVE_EP = '';
export const READ_ORANISATION_CBC_BY_ID_EP = '';
export const READ_ORANISATION_CBC_DROPDOWN_EP = '';
export const READ_ORANISATION_CBC_LIST_EP = '';
export const READ_ORANISATION_CBC_SHORTCUT_PANE_EP = '';
//contract

//lot-contract
export const CONTRACT_SAVE_EP = 'Contractor/CreateHeader';
export const READ_CONTRACT_BY_ID_EP = 'Contractor/GetContractorById/';
export const READ_CONTRACT_DROPDOWN_EP = 'Contractor/GetContractorDropdown';
export const READ_CONTRACT_LIST_EP = 'Contractor/ContractorFilter';
export const READ_CONTRACT_SHORTCUT_PANE_EP = 'Contractor/ShortcutPaneData';
export const DELETE_CONSTRUCTOR_EP = 'Contractor/ConstructorWorkFlowDelete';
export const AWARD_TENDER_EP = 'Contractor/AddTenderAwardWinner';
export const LOT_FILE_UPLOAD_EP = '/Contractor/LotFileUpload';

//lot-constructor
export const UPDATE_CONTRACTOR_WORKFLOW_EP =
  'Contractor/UpdateContractorWorkflow';
export const READ_CONSTRUCTOR_BY_ID_EP = 'Contractor/GetConstructorById/';
export const GET_CONSTRUCTORS_DATA_EP = 'Contractor/GetConstructorByTaxonomy';

export const CONTRACTOR_PERSON_FILTER_EP = 'Contractor/PersonFilter';
export const CONTRACTOR_LOT_EXCEL_UPLOAD_EP =
  'Contractor/ContractorLotExcelUpload';
export const CONTRACTOR_SEND_INVITATION_EP = 'Contractor/SendInvitation';
export const CONTRACTOR_PUBLISH_TENDER_EP = 'Contractor/PublishTender';
export const LOT_PERSON_FILTER_EP = 'Contractor/LotPersonFilter';
export const SEND_INVITATION_TO_CONTRACTOR_EP = 'Contractor/345';

//cab
export const GET_CONTRACTORS_DATA_EP =
  'CentralAddressBook/GetContractorTaxonomyList';

{
  /*BmeRequirement*/
}

{
  /*BmeRequirement*/
}

export const STANDARD_MAIL_SAVE_EP = 'Standardmail/CreateStandardmail';
export const READ_STANDARD_MAIL_BY_ID_EP = 'Standardmail/StandardmailgetById/';
export const READ_STANDARD_MAIL_DROPDOWN_EP = '';
export const READ_STANDARD_MAIL_LIST_EP = 'Standardmail/StandardmailFilter';
export const READ_STANDARD_MAIL_SHORTCUT_PANE_EP = '';
export const UPLOAD_CONTRACTOR_PDF_EP = '/PdfToExcel/PdfToExcelTest';

//lot-invitation
export const READ_LOT_INVITATION_BY_ID_EP =
  'ContractingUnitTenants/GetContractorById/';
export const ACCEPT_INVITATION_EP = 'Contractor/ApproveInvitation';
export const SUBSCRIBE_LOT_EP = 'Contractor/SubscribeLot';
export const DOWNLOAD_LOT_DOCUMENTS_EP = 'Contractor/DownloadLotDocuments';

export const LOAD_USER_INFORMATION_EP = '/Contractor/GetUserInformation';
export const READ_LOT_SUBSCRIBE_BY_ID_EP =
  'ContractingUnitTenants/GetContractorByIdForSubscribMail/';
export const VP_UPDATE_TREE_INDEX = 'VP/TreeIndex';
export const VP_UPDATE_NEW_SIBLING_TREE_INDEX = 'VP/TreeIndexSiblingAdd';
export const VP_MY_ENV_PBS_DISPLAY_ORDER = 'VP/PbsDisplayOrder';
export const READ_DOWNLOAD_ALL_DOCUMENT_EP = 'Contractor/LotDocsZipDownload';

//My DayPlanning
export const MY_DAY_PLANNING_SAVE_EP = '';
export const READ_MY_DAY_PLANNING_BY_ID_EP = '';
export const READ_MY_DAY_PLANNING_DROPDOWN_EP = '';
export const READ_MY_DAY_PLANNING_LIST_EP = '';
export const READ_MY_DAY_PLANNING_SHORTCUT_PANE_EP = 'VP/DayPlanningFilter'; // Not used !
export const UPDATE_MY_DAY_PLANNING_PMOL_EP = 'VP/PmolAssignDayPanning';
export const UPDATE_MY_DAY_PLANNING_PBS_EP = 'VP/PbsAssing';
export const UPDATE_MY_DAY_PLANNING_PROJECT_EP = 'VP/ProjectAssign';
export const READ_MY_DAY_PLANNINNG_TEAMS_LIST_EP = 'VP/DayPlanningListData';
export const READ_MY_DAY_PLANNINNG_REOSURCE_LIST_EP = 'VP/GetAvailableTeams';
export const UPDATE_MY_DAY_PLANNING_PMOL_PERSON_EP =
  'VP/PersonAssignDayPlanning';
export const UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_EP =
  'VP/CreateNewTeamDayPlanning';
export const UPDATE_MY_DAY_PLANNINNG_VEHICLE_EP = 'VP/VehicleAssignDayPlanning';
export const ASSIGN_VEHICLE_TO_TEAM = 'VP/VehicleAssignTeamDayPlanning'
export const CLONE_PMOL_DAY_PLANNING_EP = 'Pmol/ClonePmolDayPlanning';
export const TOOL_ASSIGN_MY_DAY_PLANNING_EP = 'Vp/ToolAssignDayPlanning';
export const REMOVE_LABOUR_DAY_PLANNING_EP = 'Vp/RemovePerson';
export const READ_VP_PROJECT_LIST_EP = 'VP/ProjectSearchForVp';

//project DayPlaning
export const READ_PROJECT_DAY_PLANNING_SHORTCUT_PANE_EP =
  'VP/DayPlanningFilter';
export const READ_PROJECT_DAY_PLANNING_PROJECT_LIST_EP = 'VP/ProjectsWithPmol';
export const READ_PROJECT_DAY_PLANNING_PMOL_LIST_EP = 'VP/DayPlanningListData'; // Not used !
export const READ_PROJECT_DAY_PLANNING_RESOURCE_LIST_EP =
  'VP/GetAvailableTeams';
export const RESIZE_PMOL_ON_PROJECT_DAY_PLANNING_EP =
  'VP/UpdatePmolStartEndDate';
export const ASSIGN_PMOL_TO_PROJECT_DAY_PLANNING_EP = 'VP/PmolExecutionDateSet';
export const ASSIGN_TEAM_TO_PROJECT_DAY_PLANNING_EP =
  'VP/AddTeamMemberToProjectPmols';
export const ASSIGN_TEAM_TO_PMOL_DAY_PLANNING_EP = 'VP/AddTeamMemberToPmol';
export const ASSIGN_VEHICLE_TO_PROJECT_DAY_PLANNING_EP =
  'VP/AddToolsToProjectPmols';
export const ASSIGN_VEHICLE_TO_PMOL_DAY_PLANNING_EP = 'VP/AddToolsToPmol';
export const LOAD_BU_DROPDOWN_DATA_EP = 'VP/BuFilterForDayPlanning';
export const GENERATE_RFQ_EP = 'VP/GenerateRFQForProjectDayPlanning';
export const CREATE_NEW_PROJECT_MY_ENV_EP = 'VP/CreateNewProjectMyEnv';
export const CREATE_CPC_RESOURCE_FAMILY_EP =
  'CpcResourceFamily/CreateCpcResourceFamily';
export const DELETE_LABOUR_PROJECT_DAY_PLANNING_EP =
  'VP/DeleteLabourFromProjectDayPlanning';
export const LOAD_CPC_RESOURCE_FAMILY_EP =
  'CpcResourceFamily/ReadCpcResourceFamily';
export const PLAN_BOARDS_PMOL_STATUS_UPDATE_EP = 'Pmol/PmolStatusUpdate';
export const GET_ORG_TEAMS_TAXONOMY_EP =
  '/OrganizationSettings/GetOrganizationTaxonomyListForProjectPlan';
export const GET_BU_PERSON_WITH_COMPETENCE_EP =
  'Pmol/GetBuPersonWithCompetence';
export const ADD_MULTIPLE_MEMBERS_TO_PMOL_EP = 'VP/AddMultipleMembersToPmol';
export const PLAN_BOARDS_PMOL_STATUS_TITLE_EP = 'VP/UpdatePmolTitleMyEnv';

// ciaw
export const GENERATE_CIAW_EP = 'Ciaw/CreateCiaw';
export const READ_CIAW_LIST_EP = 'Ciaw/FilterCiaw';
export const READ_CIAW_DROPDOWN_DATA_EP = 'Ciaw/CiawDropDownData';
// export const READ_CIAW_BY_ID_EP = 'Ciaw/CiawGetById/';
export const READ_CIAW_BY_ID_EP = 'Ciaw/CiawGetById';
export const UPDATE_CIAW_REGISTRATION_STATUS_EP = 'Ciaw/UpdateCiawRegistrationStatus'
export const READ_CIAW_SITE_CODE_BY_ID_EP = 'Ciaw/ProjectCiawSiteGet/';
export const CREATE_PRODUCT_CIAW_SITE_EP = 'Ciaw/ProductCiawSiteCreate';
export const CREATE_PROJECT_CIAW_SITE_EP = 'Ciaw/ProjectCiawSiteCreate';
export const READ_CIAW_SITE_PRODUCT_CODE_BY_ID_EP = 'Ciaw/ProductCiawSiteGet/';
export const READ_CAB_CERTIFICATION_CIAW_EP =
  'CentralAddressBook/GetCabCertificationCiaw';
export const VEHICLE_TRACKING_LIST_EP = 'TimeRegistration/GetVehicles';
export const SEND_CIAW_EP = 'Ciaw/CiawSendRequest';
export const CANCEL_CIAW_EP = 'Ciaw/CiawCancelPresences';
export const CIAW_CANCEL_DROPDOWN_EP = 'Ciaw/CiawCancelDropDownData';
export const SEND_CIAW_WARNING_EMAIL_EP = 'Ciaw/SendCiawWarningEmail/';

//Time Registration
export const CREATE_PMOL_TIME_REGISTRATION_COMMENT_EP =
  'Pmol/CreatePersonCommentCard'; //TODO: need api integration here
export const GET_MAP_DATA_VISUALIZATION_EP = 'Pmol/ReadPmolId/';
export const GET_PMOL_PERSON_LIST_EP = 'Pmol/GetPMolPlannedWorkLabour/';
export const READ_TR_PMOL_BY_PERSON_EP = 'Pmol/GetPmolByPerson';
export const READ_TR_APP_DATA_BY_PERSON_EP = 'Pmol/GetTrAppData';
export const READ_PMOL_VEHICLE_POSITIONS_EP =
  'TimeRegistration/GetLabourPmolVehicalesPositions';
export const READ_VTS_DATA_BY_PERSON_EP = 'TimeRegistration/GetVtsDataByPerson';
export const READ_TOTALS_FOR_TR_MODAL_EP = 'Pmol/GetTrAppData';
export const READ_PERSON_COMMENT_CARD_EP = 'Pmol/GetPersonCommentCard';
export const UPDATE_PMOL_TIME_REGISTRATION_COMMENT_EP = 'Pmol/AddPersonComment';
export const READ_PMOL_COMMENT_CARD_EP = 'Pmol/GetPmolCommentCard';

//thAutomation
export const LOAD_PRODUCT_LIST_THA = 'SmartBoard/GetProductsWithTrucks';
export const LOAD_TRUCK_PRODUCT_LIST_THA = 'SmartBoard/GetTruckAssignData';
export const ASSIGN_TRUCK_TO_PRODUCT_THA_EP = 'SmartBoard/AddTrucksToProduct';
export const LOAD_BOTTOM_TRUCK_WITH_PRODUCT_EP =
  'SmartBoard/GetTruckWithProduct';
export const DELETE_TRUCK_FROM_DAY_EP = 'SmartBoard/RemoveTruckFromDay';
export const DELETE_DELIVERY_PRODUCT_FROM_DAY_EP = 'SmartBoard/RemoveTHProduct';
export const UPDATE_PRODUCT_TIME_EP = 'SmartBoard/UpdateThProduct';
export const LOAD_BOTTOM_PUMP_WITH_PRODUCT_EP = 'SmartBoard/GetPumpWithProduct';
export const ASSIGN_PUMP_TO_PRODUCT_THA_EP = 'SmartBoard/AddPumpsToProduct';
export const LOAD_TH_RESOURCE_FAMILY_EP = 'SmartBoard/GetThResourceFamilies';
export const GET_SMART_BOARD_COLOR_CODES_EP = 'SmartBoard/GetColourCodes';

export const LOAD_LABOUR_LIST_MY_ENV_EP =
  'CorporateProductCatalog/CpcLobourFilterMyEnv';
export const GET_HTML_PRINT_DATA_EP = 'Pmol/PmolDataForPrint';

//Burn Down Chart
export const GET_BURN_DOWN_CHART_DATA_EP = 'BurndownChart/GetBurnDownChartData';

//WBS
export const READ_WBS_CREATE_LIST_DATA_EP = 'Wbs/WbsFilter';
export const GET_WBS_CREATE_LEVEL_DATA_EP = 'Wbs/GetWbsDropdown';
export const CREATE_WBS_NODE_EP = 'Wbs/CreateWbs';
export const READ_WBS_TAXONOMY_LIST_EP = 'Wbs/GetWbsById/'; //'Wbs/WbsTaxonomyList';
export const CREATE_WBS_TEMPLATE_EP = 'Wbs/CreateTemplate';
export const GET_WBS_PRODUCT_BY_ID_EP = 'Wbs/WbsProductGetById/';
export const CREATE_WBS_PRODUCT_EP = 'Wbs/WbsProductCreate';
export const COPY_WBS_TO_PROJECT_EP = 'Wbs/CopyWbsToProject/';
export const RENAME_WBS_TEMPLATE_EP = 'Wbs/WbsEditTemplateName';
export const SHAREPOINT_DOCUMENT_CONTENT_TYPE_COLUMN_EP =
  'Wbs/GetWbsDocumentIdByTaskId';
export const SHAREPOINT_DOCUMENT_CONTENT_TYPES = 'Wbs/GetContentTypes';
export const SHAREPOINT_DOCUMENT_CONTENT_TYPE_UPDATE_EP = 'Wbs/UpdateFile';

export const GET_WBS_TASK_LIST_EP = 'Wbs/WbsTaskFilter';
export const GET_WBS_GROUP_TASK_LIST_EP = 'Wbs/WbsTaskGroupFilter';
export const GET_WBS_TASK_BY_ID_EP = 'Wbs/WbsTaskGetById';
export const SAVE_WBS_TASK_EP = 'Wbs/WbsTaskCreate';
export const GET_WBS_TAXONOMY_EP = 'Wbs/WbsTaxonomyList';
export const GET_WBS_TASK_DROPDOWN_EP = 'Wbs/GetWbsDropdown';
export const GET_WBS_TAXONOMY_BY_TEMPLATE_ID_EP =
  'Wbs/GetWbsTaxonomyByTemplate/';
export const GET_WBS_TASK_INSTRUCTION_LIST_EP = 'Wbs/GetWbsTaskInstructionList';
export const GET_WBS_TASK_IS_FAV_EP = 'Wbs/WbsTaskIsFavourite';
export const WBS_TASK_STATUS_UPDATE_EP = 'Wbs/WbsTaskStatusUpdate';
export const UPLOAD_WBS_SHAREPOINT_DOC_EP = 'Wbs/UploadWbsDocument';

//PMOL
export const CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP =
  'Pmol/ClonePmolMultipleDays';

//Midterm Planning
export const READ_MID_TERM_PLANNING_EP = 'VP/GetPbsTreeStructureDataForMTP';
export const ADD_MID_TERM_LABOUR_EP = 'PbsResource/PbsLabourAssign';
export const ADD_MID_TERM_TEAM_ASSIGN = 'PbsResource/PbsLabourTeamAssign';
export const ADD_MID_TERM_RENAME = 'PbsResource/PbsRenameTeamLabour';
export const DELETE_MID_TERM_LABOUR_EP = 'PbsResource/PbsAssignedLabourDelete';
export const READ_MID_TERM_ORG_TAXONOMY_EP =
  'vp/GetMTPOrganizationTaxonomyList';
export const UPDATE_WBS_TAXONOMY_PARENT_NODE_EP = 'Wbs/WbsDragAndDrop';

//WBS Issue
export const READ_WBS_ISSUE_LIST_EP = 'Issue/IssueFilter';
export const SAVE_WBS_ISSUE_EP = 'Issue/CreateIssue';
export const GET_WBS_ISSUE_BY_ID_EP = 'Issue/IssueGetById/';
export const GET_WBS_ISSUE_DROPDOWN_DATA_EP = 'Issue/GetIssueDropDownData';

//pbs
export const READ_READ_CBC_CONTRACTOR_EP = 'Contractor/GetAwardedLotInProject';
export const SAVE_CBC_TO_PBS_EP = 'Pbs/AddPbsCbcResource';
export const REMOVE_CBC_TO_PBS_EP = 'Pbs/DeletePbsCbcResource';

export const READ_CBC_TO_PBS_EP = 'Pbs/GetPbsCbcResourcesById/';

export const READ_DELIVERABLES_TO_PBS_EP = 'Pbs/GetPmolDeliverablesByPbsId/';

//pmol cbc endpoint
export const READ_PMOL_CBC_CONTRACTOR_EP = 'Contractor/GetAwardedLotInProject';
export const SAVE_CBC_TO_PMOL_EP = 'Pmol/AddPmolCbcResource';
export const REMOVE_CBC_TO_PMOL_EP = 'Pmol/DeletePmolCbcResource';
export const READ_CBC_TO_PMOL_EP = 'Pmol/GetPmolCbcResourcesById/';

export const GET_PBS_CONTRACTOR_EP = 'Pbs/GetPbsLotIdById/';

//day planing create pmol popup
export const GET_PBS_CBC_BY_PBS_ID_EP = '/Pbs/GetPbsCbcResourcesByIdForMyCal';

//  Task component
export const SAVE_TASK_MODAL_DATE_EP = 'Wbs/WbsTaskDateUpdate';

// Create teams folder when product create
export const CREATE_TEAMS_FOLDER_FROM_CREATE_PBS = 'Wbs/CreateFolderToTeams';
export const GET_FOLDERS_FROM_TEAMS = 'Wbs/GetFolderFromTeams';
// Retrieve GraphApi token
export const GET_GRAPH_API_TOKEN = 'Wbs/GraphApiAccessToken';
export const UPLOAD_DOCUMENTS_TO_TEAMS_EP = 'Wbs/UploadWbsDocumentToTeams';

// Asset Registers
export const GET_ASSET_REGISTERS_AXH_EP = 'AssetRegister/GetAssetRegister/AxH';
export const CREATE_ASSET_REGISTER_ITEM = 'AssetRegister/CreateAssetItem/AxH';
export const UPDATE_ASSET_REGISTER_AXH_EP = 'AssetRegister/UpdateAssetItem/AxH';

export const GET_ASSET_REGISTERS_AXH_BY_PRODUCT_EP = 'Pbs/GetAssetRegisterItemsAxHByProduct';
export const DELETE_ASSET_ITEM_EP = 'Pbs/DeleteLinkAssetItemToPBS'
export const LINK_ASSET_REGISTER_TO_PRODUCT_FROM_CU_EP = 'AssetRegister/LinkAssetItemToPBS';

// History PMOL/Product 
export const GET_PMOL_PRODUCT_HISTORY_INTERVENTIONS = 'pmol/GetProductPmolHistoryInterventions';

// pmol remove
export const DELETE_PMOL = "Pmol/RemovePmol"