import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useStore } from 'react-redux';
import client from '../../../api';
import { SAVE_PBS_COMPOSITION_IMAGES } from '../../endpoints';
import { PMOL_PBS_HEADER_SECTION_LIST } from '../../util';
import { ActionButton, getTheme, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { uPrinceTheme } from '../../../../theme';
import PreviewImageSlider from './PreviewImageSlider';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const uploadIcon: IIconProps = {
  iconName: 'Upload',
  styles: iconButtonStyles,
};

const copyIcon: IIconProps = {
  iconName: 'Copy',
  styles: iconButtonStyles,
};
const removeIconStyles = {
  root: {
      color: uPrinceTheme.palette.black,
      width: 17,
      minWidth: 17,
      height: 15,
      paddingRight: 1,
      paddingLeft: 1,
      paddingTop: 1,
      paddingBottom: 1
  },
  rootHovered: { color: theme.palette.neutralDark }
};
const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: removeIconStyles
};
const stackStyles: IStackStyles = { root: { padding: 0, boxSizing:'border-box', justifyContent: 'flex-end' } };
const firstStackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
    marginRight: 'auto',
  },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
});

const FileUploaderCustomized = (props: {
  uploadedImages: any;
  uploadUrl: string;
  onSaveSuccess: (response: any) => void;
  onSaveError?: () => void;
  pmolId?: string;
  pbsId?: string;
  sectionHeading?: string;
  isImage?: boolean;
  selectAll?: boolean,
  type?: 'pbs' | 'pmol',
  label: any;
  showCopyToPbs?: boolean;
  isDragEnabled?: boolean;
  productId?: any;
  checkedItems?: string[];
  updateDocument?: (item: any, isDelete: boolean) => void,
  renameSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const inputFileRef = useRef<any>(null);

  const [dragging, setDragging] = useState(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState<boolean>(false);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  // const [isDisabledCTPBS, setIsDisabledCTPBS] = useState<boolean>(false);
  const [checkedCopyItemsId, setCheckedCopyItemsId] = useState<any[]>([]);
  const [checkedItemDetails, setCheckedItemDetails] = useState<any[]>([])
  const [deletedImageIds, setDeletedImageIds] = useState<any[]>([]);
  const [selectedImageForPreview, setSelectedImageForPreview] = useState<any>(null)

  useEffect(() => {
    // if (props.uploadedImages.length > 0) {
      setFiles(props.uploadedImages)
    // }
  }, [props.uploadedImages])

  useEffect(() => {
    if (props.isDragEnabled) {
      setIsDragAndDropEnabled(props.isDragEnabled)
    }
  }, [props.isDragEnabled])

  const {
    selectedMyDpPmol,
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const {
    formData,
  } = useSelector(
    (state: any) => state.projectBreakdown
  );

  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    // const droppedFiles = Array.from(e.dataTransfer.files);
    for (const file of e.dataTransfer.files){
      isDragAndDropEnabled && uploadFiles(file)
    }    
  };

  const uploadFiles = async (file: File) => {
    if (selectedFiles) {
      const currentFiles = selectedFiles?.map((item: any) => {
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null };
      });
      let parts = file?.name.split('.');
      // const size = file?.size;
      setFiles(
        currentFiles.concat([
          { image: URL.createObjectURL(file), id: null, isLast: true, extension: parts[parts.length - 1] },
        ]),
      );
      // setTransition(true);
      await saveImage(file, currentFiles);
    }
  };

  const saveImage = async (files: any, currentFiles: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const data = new FormData();
    if (files) {
        data.append('image', files);
    }
    await client.post(props.uploadUrl, data, { headers: headers }).then(
      async (response: any) => {
        // setTransition(false);
        if(props.pbsId && props.sectionHeading === PMOL_PBS_HEADER_SECTION_LIST.PRODUCT){
          let newImage = {
            link : response.data.result,
            type: "1"
          }
          saveProductCompositionImages(newImage, response.data.result);
        } else {
          await props.onSaveSuccess(response.data.result);
        }        
      },

      (error: any) => {
        alert(JSON.stringify(error));
        // setTransition(false);
        setFiles(props.uploadedImages);
      },
    );
  };

  const saveProductCompositionImages = async (imageList : any, imageId: any) => {
    let payload = {
      id: "",
      description: "",
      pbsProductId: props.pbsId,
      pictureList: [imageList]
    }

    await client.post(SAVE_PBS_COMPOSITION_IMAGES, payload).then(async (response: any) => {
      await props.onSaveSuccess(imageId);
    })
  }

  // Download as ZIP files
  const getImageFormat = (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const arr = (new Uint8Array(reader.result as ArrayBuffer)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        let format = '';
        if (header.startsWith('ffd8')) format = 'jpeg'; // JPEG
        else if (header.startsWith('8950')) format = 'png'; // PNG
        else if (header.startsWith('4749')) format = 'gif'; // GIF

        resolve(format);
      };
      reader.readAsArrayBuffer(blob);
    });
  };

  const compressImage = async (imageBlob: Blob, maxWidth: number, maxHeight: number): Promise<Blob | null> => {
    return new Promise(async (resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageBlob);
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate new dimensions
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        // Determine the original format for correct Blob conversion
        const format = await getImageFormat(imageBlob);
        const mimeType = format === 'jpeg' ? 'image/jpeg' : format === 'png' ? 'image/png' : 'image/gif';

        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          resolve(blob);
        }, mimeType, 0.7); // Adjust quality (0-1)
      };
    });
  };

  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const compressAndDownload = async () => {
    const zip = new JSZip();
    const images = checkedItemDetails?.map((e: any) => e.image)
    if (images.length !== 0) {
      const promises: any = images?.map(async (imageURL: any, index: any) => {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        const compressedBlob = await compressImage(blob, 1920, 1024);
        if (compressedBlob) {
          const format = await getFileName(imageURL);
          zip.file(`${format}`, compressedBlob);
        }
      });
  
      await Promise.all(promises);
      zip.generateAsync({ type: 'blob' }).then((content) => {
        // const zipFolderName = props.type === 'pmol' ? `${selectedMyDpPmol?.projectMoleculeId}-${selectedMyDpPmol?.title?.split(" - ")?.pop()}.zip` : props.type === 'pbs' ? `${formData?.title}` : "Images.zip"
        const zipFolderName = props.type === 'pmol' ? `${selectedMyDpPmol?.projectMoleculeId}-Pictures.zip` : `${formData?.productId}-Pictures.zip`
        FileSaver.saveAs(content, zipFolderName);
      });
    }
  };

  const handleSelectAll = () => {
    const ids = props.uploadedImages?.map((item: any) => item.id && item.id)
    setCheckedCopyItemsId(ids)
    setCheckedItemDetails(props?.uploadedImages)
  }

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const copyToProductComposition =(copyImgData: any) =>{
    client.post("Pbs/CopyPicturesToComposition", copyImgData).then(
      (response: any) => {
        
      },

      (error: any) => {
        alert("not working");
      },
    );
  }

  const approveExtraImages =(imageIds: any) =>{
    client.post("Pmol/ApproveImages", imageIds).then(
      (response: any) => {
        setCheckedCopyItemsId([]);
      },

      (error: any) => {
        alert("Extra not working");
      },
    );
  }

  const approveJournalImages =(imageIds: any) =>{
    client.post("PmolJournal/ApproveJournalImages", imageIds).then(
      (response: any) => {
        setCheckedCopyItemsId([]);
      },

      (error: any) => {
        alert("Journal not working");
      },
    );
  }

  const handleCopyToPBSClick = () => {
    const journalImgIds = checkedCopyItemsId.filter((item: any) => item.isJournal).map((item: any) => item.imageId);
    const extraworkIds = checkedItemDetails?.filter((item: any) => item.isExtrawork).map((item: any) => item.id);
    const pictureList = checkedItemDetails?.map((item: any) => ({
      link: item.image,
      type: item.type
    }));

    const copyImgData = {
      id: "",
      description: "",
      pbsProductId: props.productId,
      pictureList: pictureList
    }

    if(copyImgData && copyImgData.pbsProductId!=null && copyImgData.pictureList.length>0){
      copyToProductComposition(copyImgData);
    }

    if(extraworkIds && extraworkIds.length>0){
      const idList = {
        imageIds: extraworkIds
      }
      approveExtraImages(idList);
    }

    if(journalImgIds && journalImgIds.length>0){
      const idList = {
        imageIds: journalImgIds
      }
      approveJournalImages(idList);
    }

    const currentFiles = selectedFiles.map((item: any) => {
      if(extraworkIds?.includes(item.id) || journalImgIds?.includes(item.id)){
        return { 
          image: item.image,
          id: item.id,
          isLast: false,
          extension: item.extension ? item.extension : null,
          isApproved : true,
          type: item.type
        };
      }
      else{
        return { 
          image: item.image, 
          id: item.id, 
          isLast: false, 
          extension: item.extension ? item.extension : null, 
          isApproved : item.isApproved, 
          type: item.type
        };
      }
    });

    setFiles(currentFiles);
    if(props.updateDocument){
      props?.updateDocument(currentFiles, false);
    }
  };

  const deleteImage = (id: string, section : string) => {
    const extraWorkImages = store.getState()?.pmol?.extraWork?.imageList?.map((item:any) => {return {...item, image: item.link, isExtrawork: true}});
    const journalImages = store.getState()?.pmol?.journal?.pictureList?.map((item: any) => {return {...item, image: item.link, isJournal: true}});
    const handshakeImages = store.getState()?.pmol?.handshakeDocs?.map((item: any) => {return {...item, isHandshake: true}});
    let deleteId = deletedImageIds
    deleteId.push(id);
    setDeletedImageIds(deleteId);

    if(props.updateDocument){
        if(section === "extraWork"){
            const updatedFiles = extraWorkImages?.filter((file: any) => !deleteId?.includes(file.id));
            setFiles(updatedFiles);
            props.updateDocument(updatedFiles, true);
        } else if(section === "journal"){
            const journalImageList = journalImages.map((file: any) => {
              if (deleteId.includes(file.id)) {
                return {
                  ...file,
                  isDelete: true,
              }}
              return file;
            })
            setFiles(journalImageList);
            props.updateDocument(journalImageList, false);
        } else if(section === "handShake"){
          const updatedFiles = handshakeImages?.filter((file: any) => !deleteId?.includes(file.id));
          setFiles(updatedFiles);
          props.updateDocument(updatedFiles, true);
        } else if(section === "product"){
          props.updateDocument(id, true);
        }
    }
  }

  return (
    <div  className={`dropzone ${dragging ? 'dragging' : ''} input-file`} 
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div>
        <input
          style={{ display: 'none' }}
          ref={inputFileRef}
          type="file"
          id='file'
          accept={props.isImage ? 'image/*' : '*'}
          multiple={true}
          onChange={async (e) => {
            if(e.target.files && e.target.files.length>0){
              for(const file of e.target.files){
                await uploadFiles(file);
              }
            }
          }}
        />
      </div>
      <>
        <Stack
          horizontal
          // horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={firstStackItemStyles}>
            <ActionButton
              className={classNames.actionButton}
            >
              <Label>{props.label}</Label>
            </ActionButton>          
          </Stack.Item>
          {props.selectAll && 
            <>
              <Stack.Item styles={stackEditbarItemStyles}>
                <PrimaryButton style={{marginRight: '8px'}} onClick={handleSelectAll}>
                  {t('selectAll')}
                </PrimaryButton>
                <PrimaryButton 
                  disabled={checkedCopyItemsId?.length === 0 ? true : false}
                  onClick={compressAndDownload}
                >
                  {t('download')}
                </PrimaryButton>
              </Stack.Item>
            </>
          }

          <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={uploadIcon}
              allowDisabledFocus
              // disabled={props.disable}
              onClick={() => {
                showFileDialog();
              }}
            >
              <Label className={classNames.actionButton}>{t('upload')}</Label>
            </ActionButton>
          </Stack.Item>
          {props.showCopyToPbs && (
            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                iconProps={copyIcon}
                allowDisabledFocus={checkedCopyItemsId?.length === 0 ? true : false}
                disabled={checkedCopyItemsId?.length === 0 ? true : false}
                style={{opacity: checkedCopyItemsId?.length === 0 ? 0.5 : 1}}
                onClick={() => {
                  handleCopyToPBSClick();
                }}
              >
                <Label className={classNames.actionButton}>{t('copyToPbs')}</Label>
              </ActionButton>
            </Stack.Item>
          )}
        </Stack>
      </>
      {/* Image preview  */}
      <div>
        <PreviewImageSlider
          uploadedImages={selectedFiles}
          setCheckedCopyItemsId={setCheckedCopyItemsId}
          setCheckedItemDetails={setCheckedItemDetails}
          checkedImages={checkedCopyItemsId}
          renameSuccess={(item: any) => {
            if(item && props.renameSuccess){
              props.renameSuccess();
            }
          }}
          pmolId ={props.pmolId}
          pbsId = {props.pbsId}
          sectionHeading={props.sectionHeading}
          deleteImage = {(id: string, section: string) => {
            if(id && section){
              deleteImage(id, section)
            }
          }}
          selectedImageForPreview={selectedImageForPreview}
          setSelectedImageForPreview={setSelectedImageForPreview}
        />
      </div>
    </div>
  )
}

export default FileUploaderCustomized